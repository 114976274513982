@import '~bootstrap/scss/bootstrap';
@import './custom.scss';
@import './animations.scss';

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .page{
    margin-top: 80px;
    padding-bottom: 5em;
  }

  .content-header {
    margin-bottom: 20px;
  }
  .form-label {
    width: 100%;
    text-align:left;
  }

  @media(min-width: 768px) {
    .container.flipbox { 
    //  margin-top:10rem;
    }
  }
  @media(min-width: 768px) {
  .container.flipbox{
    display: flex;
    justify-content: space-around;
  }
  .jumbotron.flipbox {
    min-width:30%;
    max-width:30%;

  }
}

@media(max-width: 768px) {
  .container.flipbox{
    display: block;
    //justify-content: space-around;
  }
  .jumbotron.flipbox {
    width:fit-content;
    

  }
}
.jumbotron.flipbox{
  display: flex;
  background:#f5f5f5;
    border: 1px #777 solid;
    flex-direction: column;
    width: 25%;
    margin: .25rem 0;
    padding: 2em;
    border-radius:8px;
   
    // box-shadow: #777 2px 1px 5px 0px;
    &:hover {
      box-shadow: #777 0px 5px 15px;
    }
    h3 {
      color:#178cbd;
      font-size:1.5em;
    }
   
}
hr {
  width: 50%;
  margin:auto;
  margin-bottom: 4em;
  margin-top: 4em;
}

.carousel-item img {
  max-height: 30em !important;
}
.main.navbar{
  padding-left:1em;
  a {
    color:#178cbd;
  }
}