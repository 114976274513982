// customizations
$theme-colors: (
 "info" :   tomato,
 "danger":  teal
);

// import bootstrap to apply changes
@import '~bootstrap/scss/bootstrap';


.carousel-caption.main {
    color:#063556;

}
.content-header h1 {
    text-align:center;
    color: #007bff;
    background:transparent;
    width:100%;
    margin:0;
    padding:0;
    animation: 3s fadein 1 alternate ease-in;
    
}
.content {
    text-align:left;
}
.summary {
    font-size: 1.5 em;
}
.details-header {
    font-size: 1.25em;
}
.details {
    font-size:1em;
}

